import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout';
import VerticalLayout from '../Layouts/index';

//routes
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import { AuthProtected } from './AuthProtected';
import Login from '../pages/Authentication/Login';
import { useDispatch, useSelector } from 'react-redux';
import ErrorPage from '../pages/AuthenticationInner/Errors/404ErrorPage';
import Callback from './callbackPage';
import { fetchPermissions } from '../permissionsSlice';
import { authConfig, UserManagerInstance } from '../Components/Common/functions/authConfig';

const Index = () => {
  function clearAuth() {
    void UserManagerInstance.signoutRedirect({ post_logout_redirect_uri: authConfig.post_logout_redirect_uri })
    localStorage.removeItem('operatorname')
  }

  const checkAccessTokenAndRenew = async () => {
    const user = await UserManagerInstance.getUser()

    if (user?.expires_in && user?.expires_in <= Date.now()) {
      try {
        await UserManagerInstance.signinSilent()
      } catch (error) {
      }
    }
  }

  useEffect(() => {
    const tokenCheckInterval = setInterval(checkAccessTokenAndRenew, 600000) // Check every 10 minutes

    return () => {
      clearInterval(tokenCheckInterval)
    }
  }, [])

  useEffect(() => {
    const user = UserManagerInstance.getUser()
    if (user?.expires_in && user?.expires_in <= Date.now()) {
      clearAuth()
    }
  }, [])

  UserManagerInstance.events.addAccessTokenExpiring(() => {
    // Perform the background token refresh
    void UserManagerInstance.signinSilent().then((user) => {
    })
  })

  const location = useLocation();
  useEffect(() => {
    const route = authProtectedRoutes.find(route => route.path === location.pathname);
    if (route) {
      document.title = route.title || "K2Charge";
    } else {
      document.title = "K2Charge";
    }
  }, [location.pathname]);

  let permissions, parsedPermissions;

  if (window.env.REACT_APP_LOGIN_ENVIRONMENT === "Native") {
    permissions = localStorage.getItem('permissionss');
    parsedPermissions = permissions ? JSON.parse(permissions) : [];
  } else {
    const permissionsFromState = useSelector((state) => state.permissions.permissions);
    permissions = permissionsFromState;
    parsedPermissions = permissionsFromState ? permissionsFromState : [];
  }
  const dispatch = useDispatch();
  const roleName = localStorage.getItem('roleName')
  const [permissionsFetched, setPermissionsFetched] = useState(false);
  const [authenticated, setAuthenticated] = useState(null);
  useEffect(() => {
    dispatch(fetchPermissions())
      .then(() => setPermissionsFetched(true))
      .catch(() => setPermissionsFetched(true));
  }, [dispatch]);
  if (!permissionsFetched) {
    return null;
  }
  return (
    <React.Fragment >
      <Routes>
        <Route path="/" element={<Login userManager={authConfig} />} />
        {/* <Route path="/" element={<Login userManager={UserManagerInstance} />} /> This is for direct navigating to the account page */}
        <Route path="/callback" element={<Callback userManager={UserManagerInstance} setAuth={setAuthenticated} />} />
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        {permissions && (
          <Route>
            {authProtectedRoutes.map((route, idx) => {
              const resourceName = route?.path?.startsWith('/')
                ? route.path.substring(1)
                : route.path;

              const hasPermission =
                Array.isArray(parsedPermissions) &&
                parsedPermissions.some(
                  (permission) => permission.resourceName === resourceName
                );
              const isCreateCustomerRoute =
                route.path === '/create-customer' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isAICustomerRoute =
                route.path === '/chatbot' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                ) && roleName === 'telcoAdmin';
                const isUploadDocumentRoute =
                route.path === '/documents' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'document'
                ) && roleName === 'Customer';
              const isListOperatorRoute =
                route.path === '/operator-listing' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'operator' &&
                    permission.permissionList.includes('create')
                );
              const isCreateOperatorRoute =
                route.path === '/create-operator' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'operator' &&
                    permission.permissionList.includes('create')
                );
              const isProfileOperatorRoute =
                route.path === '/business-profile' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName && roleName === 'telcoAdmin' || roleName === 'operatorAdmin' || roleName === 'genericAdmin' || roleName === 'ISPOperatorAdmin'
                )
              const isEditOperatorRoute =
                route.path === '/edit-operator/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'operator' &&
                    permission.permissionList.includes('create')
                );
              const isViewOperatorRoute =
                route.path === '/view-operator/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'operator' &&
                    permission.permissionList.includes('create')
                );
              const isDashboardRoute =
                route.path === '/dashboard' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'dashboard'
                );
              const isSubscriptionRoute =
                route.path === '/subscription' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'subscription' &&
                    permission.permissionList.includes('view') && roleName === 'Customer'
                );
              const isInvoicesRoute =
                route.path === '/invoices' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isChangePlanRoute =
                route.path === '/change-offer/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isChangePlanSummaryRoute =
                route.path === '/change-offer-summary/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isOrderRoute =
                route.path === '/order-details' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isFilterRoute =
                route.path === '/order-filtering/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isSelectRoute =
                route.path === '/order-select' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isOrderListingRoute =
                route.path === '/orders' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isOrderViewRoute =
                route.path === '/view-order/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isAddOnRoute =
                route.path === '/add-on/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isCancelOrderRoute =
                route.path === '/cancel-order/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isUpdateCustomerRoute =
                route.path === '/update-customer/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('update')
                );
              const isEditStationRoute =
                route.path === '/station/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'station' &&
                    permission.permissionList.includes('update')
                );
              const isCreateResourceRoute =
                route.path === '/create-resources' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'resource' &&
                    permission.permissionList.includes('create')
                );
              const isEditResourceRoute =
                route.path === '/edit-resource/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'resource' &&
                    permission.permissionList.includes('update')
                );
              const isCreateUomRoute =
                route.path === '/create-unit' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'uom' &&
                    permission.permissionList.includes('create')
                );
              const isEditUomRoute =
                route.path === '/edit-uom/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'uom' &&
                    permission.permissionList.includes('update')
                );
              const isCreateAttributeRoute =
                route.path === '/create-attribute' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'uom' &&
                    permission.permissionList.includes('create')
                );
              const isAttributeRoute =
                route.path === '/attribute' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'uom' &&
                    permission.permissionList.includes('create')
                );
              const isUpdateAttributeRoute =
                route.path === '/update-attribute/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'uom' &&
                    permission.permissionList.includes('create')
                );
              const isServiceRoute =
                route.path === '/create-service' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'service' &&
                    permission.permissionList.includes('create')
                );
              const isEditServiceRoute = 
                route.path==='/edit-service/:id' && 
                parsedPermissions.some(
                  (permissions) => 
                      permissions.resourceName==='service'&&
                      permissions.permissionList.includes('update')
                );
              const isCloneServiceRoute =
                route.path === '/clone-service/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'service' &&
                    permission.permissionList.includes('create')
                );
              const isCreateOfferRoute =
                route.path === '/create-offer' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'offer' &&
                    permission.permissionList.includes('create')
                );
              const isImportDataRoute =
                route.path === '/import-data' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'offer' &&
                    permission.permissionList.includes('view')
                );
              const isUpdateOfferRoute =
                route.path === '/update-offer/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'offer' &&
                    permission.permissionList.includes('update')
                );
              const isCloneOfferRoute =
                route.path === '/clone-offer/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'offer' &&
                    permission.permissionList.includes('update')
                );
              const isSimulateRoute =
                route.path === '/simulate/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isViewCustomerRoute =
                route.path === '/view-customer/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('view')
                );
              const isCreateReservationRoute =
                route.path === '/create-reservation' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'reservation' &&
                    permission.permissionList.includes('create')
                );
              const isCreateReservationRouteID =
                route.path === '/create-reservation-customer/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'reservation' &&
                    permission.permissionList.includes('create')
                );
              const isUpadateReservationRoute =
                route.path === '/update-reservation/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'reservation' &&
                    permission.permissionList.includes('update')
                );
              const isPartnerListing =
                route.path === '/partner-listing' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isCreatePartner =
                route.path === '/create-partner' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isUpdatePartner =
                route.path === '/update-partner/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isViewPartner =
                route.path === '/view-partner/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isPolicyListingRoute =
                route.path === '/policy' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isPartnerForPolicyRoute =
                route.path === '/partner-for-policy' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isUpdatePartnerForPolicyRoute =
                route.path === '/update-partner-for-policy' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isCreatePolicyRoute =
                route.path === '/create-policy' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isUpdatePolicyRoute =
                route.path === '/update-policy/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isOfferForPolicyRoute =
                route.path === '/offer-for-policy' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isUpdateOfferForPolicyRoute =
                route.path === '/update-offer-for-policy' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer'
                );
              const isEditDeviceRoute =
                route.path === '/update-device/:deviceId' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('update')
                );
              const isViewDeviceRoute =
                route.path === '/devices' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('view')
                );
              const isCreateDeviceRoute =
                route.path === '/create-device' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'customer' &&
                    permission.permissionList.includes('create')
                );
              const isLightWeightOrder =
                route.path === '/customer/order' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'order' &&
                    permission.permissionList.includes('view') && roleName === 'Customer'
                );
              const isLightWeightInvoice =
                route.path === '/customer/invoice' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'invoice' &&
                    permission.permissionList.includes('view') && roleName === 'Customer'
                );
              const isLightWeightPayment =
                route.path === '/customer/payment' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'payment' &&
                    permission.permissionList.includes('view') && roleName === 'Customer'
                );
              const isLightWeightProfile =
                route.path === '/customer/profile' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'profileManagement' &&
                    permission.permissionList.includes("view") && permission.permissionList.includes("update") && roleName === 'Customer'
                );
              const isPolicyListRoutes =
                route.path === '/policies' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'policy' &&
                    permission.permissionList.includes('list')
                ) && parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'offer' &&
                    permission.permissionList.includes('create')
                )
              const isCreatePolicyRoutes =
                route.path === '/add-policy' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'policy' &&
                    permission.permissionList.includes('create')
                ) && parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'offer' &&
                    permission.permissionList.includes('create')
                )
              const isUpdatePoliciesRoute =
                route.path === '/edit-policies/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'policy' &&
                    permission.permissionList.includes('update')
                ) && parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'offer' &&
                    permission.permissionList.includes('create')
                )
              const isUserManagementListingRoute =
                route.path === '/user' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'userManagement' &&
                    permission.permissionList.includes('list')
                );
              const isUserManagementCreateRoute =
                route.path === '/add-user' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'userManagement' &&
                    permission.permissionList.includes('create')
                );
              const isUpdateUserRoute =
                route.path === '/edit-user/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'userManagement' &&
                    permission.permissionList.includes('update')
                )
              const isViewUserRoute =
                route.path === '/view-user/:id' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'userManagement' &&
                    permission.permissionList.includes('view')
                )
              const isProfileUserRoute =
                route.path === '/profile' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'profileManagement' &&
                    permission.permissionList.includes('update')
                )
              const isTaskRoute =
                route.path === '/tasks' &&
                parsedPermissions.some(
                  (permission) =>
                    permission.resourceName === 'task' &&
                    permission.permissionList.includes('list')
                );

              if (
                hasPermission ||
                isEditUomRoute ||
                isEditResourceRoute ||
                isListOperatorRoute ||
                isCreateOperatorRoute ||
                isEditOperatorRoute ||
                isUpdatePartner ||
                isCreatePartner ||
                isPartnerListing ||
                isCreatePolicyRoute ||
                isPartnerForPolicyRoute ||
                isOfferForPolicyRoute ||
                isCreateReservationRouteID ||
                isCreateReservationRoute ||
                isUpadateReservationRoute ||
                isCreateCustomerRoute ||
                isSimulateRoute ||
                isInvoicesRoute ||
                isCreateResourceRoute ||
                isCreateUomRoute ||
                isServiceRoute ||
                isCreateOfferRoute ||
                isUpdateCustomerRoute ||
                isEditStationRoute ||
                isViewCustomerRoute ||
                isOrderRoute ||
                isFilterRoute ||
                isSelectRoute ||
                isOrderListingRoute ||
                isPolicyListingRoute ||
                isViewPartner ||
                isEditDeviceRoute ||
                isViewDeviceRoute ||
                isCreateDeviceRoute ||
                isOrderViewRoute ||
                isCancelOrderRoute ||
                isUpdatePolicyRoute ||
                isUpdateOfferForPolicyRoute ||
                isUpdatePartnerForPolicyRoute ||
                isLightWeightOrder ||
                isLightWeightInvoice ||
                isLightWeightPayment ||
                isLightWeightProfile ||
                isUpdateOfferRoute ||
                isImportDataRoute ||
                isCreateAttributeRoute ||
                isUpdateAttributeRoute ||
                isAttributeRoute ||
                isDashboardRoute ||
                isPolicyListRoutes ||
                isCreatePolicyRoutes ||
                isUpdatePoliciesRoute ||
                isViewOperatorRoute ||
                isUserManagementListingRoute ||
                isUserManagementCreateRoute ||
                isUpdateUserRoute ||
                isViewUserRoute ||
                isAddOnRoute ||
                isAICustomerRoute ||
                isChangePlanRoute ||
                isChangePlanSummaryRoute ||
                isTaskRoute ||
                isProfileUserRoute ||
                isProfileOperatorRoute ||
                isCloneOfferRoute ||
                isCloneServiceRoute ||
                isSubscriptionRoute ||
                isEditServiceRoute||
                isUploadDocumentRoute
              ) {
                return (
                  <Route
                    path={route.path}
                    element={
                      <AuthProtected>
                        <VerticalLayout>{route.component}</VerticalLayout>
                      </AuthProtected>
                    }
                    key={idx}
                    exact={true}
                  />
                );
              }
              return (
                <Route
                  path={route.path}
                  element={
                    <VerticalLayout>
                      {/* <Navigate to="/404-Error" /> */}
                      <ErrorPage></ErrorPage>
                    </VerticalLayout>
                  }
                  key={idx}
                />
              );
            })}
          </Route>
        )}
      </Routes>
    </React.Fragment>
  );
};

export default Index;