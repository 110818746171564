import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';

export default function CustomerSubscriptionCard({ fetchCustomerSummaryData }) {
    const addOnResources = fetchCustomerSummaryData?.resources?.filter(resource => resource.isAddOn);
    const nonAddOnResources = fetchCustomerSummaryData?.resources?.filter(resource => !resource.isAddOn);
    const uniqueBaseOffers = nonAddOnResources?.filter((offer, index, self) =>
        index === self?.findIndex((o) => o?.offerCode === offer?.offerCode)
    );
    const uniqueAddOnOffers = addOnResources?.filter((offer, index, self) =>
        index === self?.findIndex((o) => o?.offerCode === offer?.offerCode)
    );
    const uniqueAddOnOfferLength = uniqueAddOnOffers?.length || 0;
    const uniqueBaseOfferLength = uniqueBaseOffers?.length || 0;
    const totalSubscriptions = uniqueAddOnOfferLength + uniqueBaseOfferLength;
    return (
        <Card className="shadow-sm flex-fill">
        <CardBody className="d-flex justify-content-center align-items-stretch">
            <div className="row w-100">
                <div className="col-md-4 d-flex flex-column justify-content-center align-items-center border-end mb-3 mb-md-0">
                    <div className="bg-light rounded-1 p-2 mb-2 d-flex justify-content-center align-items-center" style={{ minWidth: '100px' }}>
                        <h2 className="m-0">{totalSubscriptions}</h2>
                    </div>
                    <CardText 
                        tag="h5" 
                        className="text-center fw-medium mb-0 mt-2" 
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {totalSubscriptions <= 1 ? "Active Subscription" : "Active Subscriptions"}
                    </CardText>
                </div>
                <div className="col-md-4 d-flex flex-column justify-content-center align-items-center border-end mb-3 mb-md-0">
                    <div className="bg-light rounded-1 p-2 mb-2 d-flex justify-content-center align-items-center" style={{ minWidth: '100px'}}>
                        <h2 className="m-0">{uniqueBaseOfferLength}</h2>
                    </div>
                    <CardText tag="h5" className="text-center fw-medium mb-0 mt-2">Base Offer</CardText>
                </div>
                <div className="col-md-4 d-flex flex-column justify-content-center align-items-center mb-3 mb-md-0">
                    <div className="bg-light rounded-1 p-2 mb-2 d-flex justify-content-center align-items-center" style={{ minWidth: '100px'}}>
                        <h2 className="m-0">{uniqueAddOnOfferLength}</h2>
                    </div>
                    <CardText tag="h5" className="text-center fw-medium mb-0 mt-2">Add On Offer</CardText>
                </div>
            </div>
        </CardBody>
    </Card>
    
    );
    
}