import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import InputField from '../../Utils/InputField';
import SelectField from '../../Utils/SelectField';
import { Button, Row, Col, Label } from 'reactstrap';
import { handleChangeForImsi } from '../../Customers/constants/HandleChangeFunctions';
import CommonDateTimePicker from '../../Utils/CommonDateTimePicker';
import moment from 'moment';
import { validityEndOptions, validityStartOptions, validityTimeOptions } from './constants/initialValues';

const UsageRatePlanRow = ({
    index,
    val,
    isEdit,
    isSubscribed,
    values,
    errors,
    touched,
    handleChange,
    handleGrantsAndChargesResourceValueAndUsageFields,
    handleGrantsAndChargesRecurringFrequencyAndUsageOptions,
    resourceNameOptions,
    uomOptions,
    pricingModelOptions,
    togglePricingModel,
    remove,
    setFieldValue,
    setFieldTouched,
    isClone
}) => (
    <div >
        <Row>
            <Col md={4} lg={4} className="mb-3">
                <Label>Usage ID<span className="text-danger"> *</span></Label>
                <InputField
                    name={`usageRatePlan[${index}].usageId`}
                    className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                    placeholder="Enter ID"
                    type="text"
                    disabled={isSubscribed && !isClone}
                    handleChange={handleChangeForImsi(setFieldValue, `usageRatePlan[${index}].usageId`)}
                    value={values.usageRatePlan[index].usageId}
                    invalid={
                        errors.usageRatePlan &&
                        errors.usageRatePlan[index] &&
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        touched.usageRatePlan[index].usageId &&
                        !values.usageRatePlan[index].usageId
                    }
                />
                {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.usageId && (
                    <p className="text-danger">{errors.usageRatePlan[index].usageId}</p>
                )}
            </Col>
            <Col md={4} lg={4} className="mb-3">
                <Label>Event Type<span className="text-danger"> *</span></Label>
                <InputField
                    name={`usageRatePlan[${index}].eventType`}
                    className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                    placeholder="Enter Event Type"
                    type="text"
                    disabled={isSubscribed}
                    handleChange={handleGrantsAndChargesResourceValueAndUsageFields(handleChange)}
                    value={values.usageRatePlan[index].eventType}
                    invalid={
                        errors.usageRatePlan &&
                        errors.usageRatePlan[index] &&
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        touched.usageRatePlan[index].eventType &&
                        !values.usageRatePlan[index].eventType
                    }
                />
                {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.eventType && (
                    <p className="text-danger">{errors.usageRatePlan[index].eventType}</p>
                )}
            </Col>
            <Col md={4} lg={4} className="mb-3">
                <Label>Resource Name<span className="text-danger"> *</span></Label>
                <SelectField
                    name={`usageRatePlan[${index}].resourceName`}
                    className={(
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        !values.usageRatePlan[index].resourceName
                    ) ? 'error-input' : ''}
                    isDisabled={isSubscribed}
                    value={values.usageRatePlan[index].resourceName}
                    handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(values.usageRatePlan[index].resourceName, `usageRatePlan[${index}].resourceName`, setFieldValue, setFieldTouched)}
                    options={resourceNameOptions}
                />
                {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.resourceName && (
                    <p className="text-danger">{errors.usageRatePlan[index].resourceName}</p>
                )}
            </Col>
            <Col md={4} lg={4} className="mb-3">
                <Label>Unit of Measurement<span className="text-danger"> *</span></Label>
                <SelectField
                    name={`usageRatePlan[${index}].unitOfMeasurement`}
                    className={(
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        !values.usageRatePlan[index].unitOfMeasurement
                    ) ? 'error-input' : ''}
                    isDisabled={isSubscribed}
                    options={uomOptions}
                    handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(values.usageRatePlan[index].unitOfMeasurement, `usageRatePlan[${index}].unitOfMeasurement`, setFieldValue, setFieldTouched)}
                    value={values.usageRatePlan[index].unitOfMeasurement}
                    invalid={
                        errors.usageRatePlan &&
                        errors.usageRatePlan[index] &&
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        touched.usageRatePlan[index].unitOfMeasurement &&
                        !values.usageRatePlan[index].unitOfMeasurement
                    }
                />
                {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.unitOfMeasurement && (
                    <p className="text-danger">{errors.usageRatePlan[index].unitOfMeasurement}</p>
                )}
            </Col>
            <Col md={4} lg={4} className="mb-3">
                <Label>Priority<span className="text-danger"> *</span></Label>
                <InputField
                    name={`usageRatePlan[${index}].priority`}
                    className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                    placeholder="Enter Priority"
                    type="number"
                    disabled={isSubscribed && !isClone}
                    handleChange={handleChangeForImsi(setFieldValue, `usageRatePlan[${index}].priority`)}
                    value={values.usageRatePlan[index].priority}
                    invalid={
                        errors.usageRatePlan &&
                        errors.usageRatePlan[index] &&
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        touched.usageRatePlan[index].priority &&
                        !values.usageRatePlan[index].priority
                    }
                />
                {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.priority && (
                    <p className="text-danger">{errors.usageRatePlan[index].priority}</p>
                )}
            </Col>
            <Col md={4} lg={4} className="mb-3">
                <Label>Flat Rate<span className="text-danger"> *</span></Label>
                <InputField
                    name={`usageRatePlan[${index}].flatRate`}
                    className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                    placeholder="Enter Flat Rate"
                    type="number"
                    disabled={isSubscribed}
                    handleChange={handleChange}
                    value={values.usageRatePlan[index].flatRate}
                    invalid={
                        errors.usageRatePlan &&
                        errors.usageRatePlan[index] &&
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        touched.usageRatePlan[index].flatRate &&
                        !values.usageRatePlan[index].flatRate
                    }
                />
                {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.flatRate && (
                    <p className="text-danger">{errors.usageRatePlan[index].flatRate}</p>
                )}
            </Col>
            <Col md={4} lg={4} className="mb-3">
                <Label>Validity Start<span className="text-danger"> *</span></Label>
                <SelectField
                    name={`usageRatePlan[${index}].validityStart`}
                    className={(
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        !values.usageRatePlan[index].validityStart
                    ) ? 'error-input' : ''}
                    isDisabled={isSubscribed}
                    options={validityStartOptions}
                    handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(
                        values.usageRatePlan[index].validityStart,
                        `usageRatePlan[${index}].validityStart`,
                        setFieldValue,
                        setFieldTouched
                    )}
                    value={values.usageRatePlan[index].validityStart}
                />
                {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.validityStart && (
                    <p className="text-danger">{errors.usageRatePlan[index].validityStart}</p>
                )}
            </Col>
            {values.usageRatePlan[index].validityStart?.value === 3 ?
                <Col md={4} lg={4} className="mb-3">
                    <Label>Start Date and Time<span className="text-danger"> *</span></Label>
                    <CommonDateTimePicker
                        name={`usageRatePlan[${index}].startDateTime`}
                        className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                        type="datetime-local"
                        disabled={isSubscribed}
                        minDate={moment()}
                        handleChange={(newValue) => {
                            setFieldValue(`usageRatePlan[${index}].startDateTime`, newValue);
                        }}
                        value={values.usageRatePlan[index].startDateTime}
                    />
                </Col>
                : null}
            {values.usageRatePlan[index].validityStart?.value === 3 ? <Col md={4} lg={4}></Col> : null}
            <Col md={4} lg={4} className="mb-3">
                <Label>Validity End<span className="text-danger"> *</span></Label>
                <SelectField
                    name={`usageRatePlan[${index}].validityEnd`}
                    className={(
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        !values.usageRatePlan[index].validityEnd
                    ) ? 'error-input' : ''}
                    isDisabled={isSubscribed}
                    options={validityEndOptions}
                    handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(
                        values.usageRatePlan[index].validityEnd,
                        `usageRatePlan[${index}].validityEnd`,
                        setFieldValue,
                        setFieldTouched
                    )}
                    value={values.usageRatePlan[index].validityEnd}
                />
                {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.validityEnd && (
                    <p className="text-danger">{errors.usageRatePlan[index].validityEnd}</p>
                )}
            </Col>
            {values.usageRatePlan[index].validityEnd?.value === 2 ?
                <Col md={4} lg={4} className="mb-3">
                    <Label>End Date and Time<span className="text-danger"> *</span></Label>
                    <CommonDateTimePicker
                        name={`usageRatePlan[${index}].endDateTime`}
                        className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                        type="datetime-local"
                        disabled={isSubscribed}
                        minDateTime={values.usageRatePlan[index].startDateTime}
                        handleChange={(newValue) => {
                            setFieldValue(`usageRatePlan[${index}].endDateTime`, newValue);
                        }}
                        value={values.usageRatePlan[index].endDateTime}
                    />
                </Col> : null}
            {values.usageRatePlan[index].validityEnd?.value === 2 && values.usageRatePlan[index].validityStart?.value === 3 ? <Col md={4} lg={4}></Col> : null}
            {/* Time Unit */}
            {values.usageRatePlan[index].validityEnd?.value === 1 ?
                <Col md={values.usageRatePlan[index].validityStart?.value === 3 ? 4 : 2} lg={values.usageRatePlan[index].validityStart?.value === 3 ? 4 : 2} className="mb-3">
                    <Label>Time Unit<span className="text-danger"> *</span></Label>
                    <SelectField
                        name={`usageRatePlan[${index}].timeUnit`}
                        className={(
                            touched.usageRatePlan &&
                            touched.usageRatePlan[index] &&
                            !values.usageRatePlan[index].timeUnit
                        ) ? 'error-input' : ''}
                        isDisabled={isSubscribed}
                        options={validityTimeOptions}
                        handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(
                            values.usageRatePlan[index].timeUnit,
                            `usageRatePlan[${index}].timeUnit`,
                            setFieldValue,
                            setFieldTouched
                        )}
                        value={values.usageRatePlan[index].timeUnit}
                    />
                    {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.timeUnit && (
                        <p className="text-danger">{errors.usageRatePlan[index].timeUnit}</p>
                    )}
                </Col>
                : null}
            {values.usageRatePlan[index].validityEnd?.value === 1 ?
                <Col md={values.usageRatePlan[index].validityStart?.value === 3 ? 4 : 2} lg={values.usageRatePlan[index].validityStart?.value === 3 ? 4 : 2} className="mb-3">
                    <Label>Time Value<span className="text-danger"> *</span></Label>
                    <InputField
                        name={`usageRatePlan[${index}].timeValue`}
                        className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                        placeholder="Enter Time Value"
                        type="number"
                        disabled={isSubscribed}
                        handleChange={handleChangeForImsi(setFieldValue, `usageRatePlan[${index}].timeValue`)}
                        value={values.usageRatePlan[index].timeValue}
                        invalid={
                            errors.usageRatePlan &&
                            errors.usageRatePlan[index] &&
                            touched.usageRatePlan &&
                            touched.usageRatePlan[index] &&
                            touched.usageRatePlan[index].timeValue &&
                            !values.usageRatePlan[index].timeValue
                        }
                    />

                    {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.timeValue && (
                        <p className="text-danger">{errors.usageRatePlan[index].timeValue}</p>
                    )}
                </Col>
                : null}
            <Col md={4} lg={4} className="mb-3">
                <Label>Pricing Model<span className="text-danger"> *</span></Label>
                <SelectField
                    name={`usageRatePlan[${index}].pricingModel`}
                    className={(!isSubscribed && (
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        !values.usageRatePlan[index].pricingModel
                    )) ? 'error-input' : ''}
                    isDisabled={isSubscribed}
                    options={pricingModelOptions}
                    handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(values.usageRatePlan[index].pricingModel, `usageRatePlan[${index}].pricingModel`, setFieldValue, setFieldTouched)}
                    value={values.usageRatePlan[index].pricingModel}
                    invalid={
                        errors.usageRatePlan &&
                        errors.usageRatePlan[index] &&
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        touched.usageRatePlan[index].pricingModel &&
                        !values.usageRatePlan[index].pricingModel
                    }
                />
                {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.pricingModel && (
                    <p className="text-danger">{errors.usageRatePlan[index].pricingModel}</p>
                )}
            </Col>

            {val?.pricingModel?.value !== 'volume' && (
                <Col md={4} lg={4} className="mb-3">
                    <Label>Per Unit Rate<span className="text-danger"> *</span></Label>
                    <InputField
                        name={`usageRatePlan[${index}].perUnitRate`}
                        className={`form-control ${isSubscribed ? "label-disable-color-edit-offer" : ""}`}
                        placeholder="Enter Per Unit Rate"
                        type="number"
                        disabled={isSubscribed && !isClone}
                        handleChange={handleChange}
                        value={values.usageRatePlan[index].perUnitRate}
                        invalid={
                            errors.usageRatePlan &&
                                errors.usageRatePlan[index] &&
                                touched.usageRatePlan &&
                                touched.usageRatePlan[index] &&
                                touched.usageRatePlan[index].perUnitRate &&
                                !values.usageRatePlan[index].perUnitRate
                                ? true
                                : false
                        }
                    />
                    {errors.usageRatePlan && errors.usageRatePlan[index] && touched.usageRatePlan && touched.usageRatePlan[index]?.perUnitRate && (
                        <p className="text-danger">{errors.usageRatePlan[index].perUnitRate}</p>
                    )}
                </Col>
            )}
            {val?.pricingModel?.value === 'volume' && (
                <Col md={4} lg={4} className="mb-3">
                    <div>
                        <Label>Per Unit Rate<span className="text-danger"> *</span></Label>
                    </div>
                    <div>
                        <Button onClick={() => togglePricingModel(index)} color="primary" disabled={isSubscribed}>
                            Add
                        </Button>
                    </div>
                </Col>
            )}
        </Row>
    </div>
);

export default UsageRatePlanRow;