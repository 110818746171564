import { Box } from "@mui/material";
import { Input } from "reactstrap";

export const attributeColoumns = (selectAllAttributes,setSelectAllAttributes,attributes,setSelectedAttribute,selectedAttribute,checkboxRef,isMobile,settingsModalOpen) => {
  const columns = [
    {
      accessorKey: 'checkbox',
      header: 'checkbox',
      Header: ({ row }) => (
        <Input type="checkbox" 
        ref={checkboxRef} 
        className=' border-black checkbox-size rounded-1' 
        checked={selectAllAttributes} 
        style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} 
        onClick={() => {
          setSelectAllAttributes(!selectAllAttributes)
          if (!selectAllAttributes) {
            setSelectedAttribute(attributes)
          }
          else {
            setSelectedAttribute([])
          }
        }} />
      ),
      enableColumnActions: false,
      enableColumnDragging: false,
      Cell: ({ row }) => (
        <Input type="checkbox" className=' border-black checkbox-size  rounded-1' 
        checked={selectedAttribute.some(p => p.name === row.original.name)} 
        style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} 
        onClick={() => {
          handleCheckboxChange(row, selectedAttribute, setSelectedAttribute)
        }
        } />
      ),
      size: isMobile ? 50 : settingsModalOpen ? 12 :7
    },
    {
      accessorKey: 'name',
      header: 'Name',
      size: 150,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 150,
      Cell: ({ cell }) => (
        <Box>
          {cell.row.original.type[0].toUpperCase() + cell.row.original.type.slice(1)}
        </Box>
      )
    },
    {
      accessorKey: 'valueType',
      header: 'Value Type',
      size: 150,
      Cell: ({ cell }) => (
        <Box>
          {cell.row.original.valueType[0].toUpperCase() + cell.row.original.valueType.slice(1)}
        </Box>
      )
    },
  ];

  return columns;
};

export const handleCheckboxChange = (row, selectedAttribute, setSelectedAttribute) => {
  let isChecked = selectedAttribute.some(p => p.name === row.original.name);
  let updatedSelectedAttributes = isChecked
    ? selectedAttribute.filter(p => p.name !== row.original.name)
    : [...selectedAttribute, row.original];
  setSelectedAttribute(updatedSelectedAttributes);
};