import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Container, FormFeedback, Input } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Row, Col, Button } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import { handleExportData } from "../Utils/ExportToCsv";
import { useNavigate } from "react-router-dom";
import { formatDate, convertNumberOfDaysToMonthYear, getDayDiff, duration, } from "./constants/DateFormat";
import { setDefault, storeRow, tabChange } from "../Utils/SetDefaultRowData";
import Simulate from "./Simulate";
import { currencyDataFormat, noCurrencyArrayDataFormat, reservationDataFormat, subscriptionDataFormat, toggleAccordion, fetchCustomerDetails, } from "./constants/constantFunctions";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerList } from "../../slices/customer/customerlist/thunk";
import { fetchCustomerSummary } from "../../slices/customer/simulate/thunk";
import { getAllStation } from "../../slices/stations/thunk";
import { getAllReservation } from "../../slices/reservations/thunk";
import { fetchCustomer } from "../../slices/customer/fetchcustomer/thunk";
import { fetchCustomerEvents } from "../../slices/customer/events/thunk";
import { ToastContainer, toast } from "react-toastify";
import { CustomerPreview } from "./CustomerPreview";
import { customerColumns, customerSearchColumns } from "./constants/CustomerTableColumns";
import { CustomerListRowOptions } from "./CustomerListRowOptions";
import { Loader } from "../Utils/Loader";
import { setWelcomeToast } from "../../slices/welcomeToaster/action";
import { setSuccessMessage, setEditSuccessMessage, setDocumentSuccessMessage, } from "../../slices/toastMessage/action";
import Select from "react-select"
import { lists } from "./constants/constantValues"
import { getCustomerData, handleClearSearch, handleSearch, handleSelectChange, navigateToCreateCustomer, handleInputChange } from "./constants/ListingCustomerFunctions";
import { exportDataForCustomer } from "./constants/exportDataForCustomer";
import { PAGE_TITLES } from "../Common/constants";
import { CustomerTableCustomTableButtons } from "./CustomerTableCustomTableButtons";
import InputField from "../Utils/InputField";
import { TableForTelco } from "./TableForTelco"
import { TelcoHeaderForCustomer } from "./TelcoHeaderForCustomer"
import { fetchCustomerListForReservation } from "../../slices/reservations/customerlist/thunk"
import { searchCustomerJsonData } from "./constants/CustomerJsonObj";
import { TableForAI } from "./TableForAI";
import { getAttributeByType } from "../../slices/attribute/getattributebytype/thunk";
import CommonModal from "../Utils/CommonModal";
import { deletecustomer } from "../../slices/customer/deletecustomer/thunk";
import { bulkDelete } from "../../slices/BulkDelete/thunk";
import { MenuItem } from '@mui/material';
import { navigateToSubscribe } from './constants/DocumentTableFunctions';

export default function ListingCustomers({ AIData, AISetTable, TableShow }) {
    const [loading, setLoading] = useState(false);
    const [renderSimulate, setRenderSimulate] = useState(false);
    const [index, setIndex] = useState(null);
    const [open, setOpen] = useState("1");
    const [activeTab, setActiveTab] = useState("0.1");
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [sorting, setSorting] = useState(false);
    const [rowCount, setRowCount] = useState(100);
    const [isError, setIsError] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [columnFilters, setColumnFilters] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const [msisdn, setMsisdn] = useState('');
    const [imsi, setImsi] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [searchFieldError, setSearchFieldError] = useState('');
    const [getTable, setGetTable] = useState(false)
    const [customerArray, setCustomerArray] = useState([]);
    const [modalForBulkDelete, setModalForBulkDelete] = useState(false)
    const [selectedRowsForBulkDelete, setSelectedRowsForBulkDelete] = useState()
    const [errors, setErrors] = useState({ msisdn: '', imsi: '', accountNumber: '' });
    const [errorMessage, setErrorMessage] = useState('Search for customer using the parameters above.')
    const [errorIcon, setErrorIcon] = useState('ri-user-fill')
    const rolename = localStorage.getItem('roleName');
    const isAIPath = window.location.pathname.includes('/chatbot');
    const [messageForPopUp, setMessageForPopUp] = useState('')
    const [customerId, setCustomerId] = useState();
    const [modal, setModal] = useState(false);
    const [searchExpanding, setSearchExpanding] = useState(false);
    const [custId, setCustId] = useState()
    const [selectAllCustomers, setSelectAllCustomers] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState([])
    const checkboxRef = useRef(null);
    const isMobile = window.innerWidth <= 768;

    const toggleModal = (id, singleOrMulti) => {
        if (singleOrMulti === "single") {
            setCustomerId(id);
            setModal(!modal);
        }
        else {
            setSelectedRowsForBulkDelete(id)
            setModalForBulkDelete(!modalForBulkDelete)
        }
        document.body.style.overflow = 'auto'
    };

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const current = new Date();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let simulateOrPreview = "";
    let subscriptionsArray = [];
    let noCurrencyArray = [];
    let currencyArray = [];
    let reservationDataArray = [];
    let exportData
    const toggle = toggleAccordion(open, setOpen);
    const permissions = useSelector((state) => state.permissions.permissions);
    const showWelcomeToast = useSelector(
        (state) => state.welcomeToast.showWelcomeToast
    );
    const showSuccessMessage = useSelector(
        (state) => state.SuccessToast.showSuccessMessage
    );
    const showDocumentSuccessMessage = useSelector(
        (state) => state.SuccessToast.showDocumentSuccessMessage
    );
    const showCustomerSuccessMessage = useSelector(
        (state) => state.SuccessToast.showDocumentSuccessMessage
    );
    const showEditSuccessMessage = useSelector(
        (state) => state.SuccessToast.showEditSuccessMessage
    ); const reservationPermission = permissions.find((resource) => resource.resourceName === 'reservation');
    const stationPermission = permissions.find((resource) => resource.resourceName === 'station');
    const customerPermission = permissions.find((resource) => resource.resourceName === 'customer');
    const orderPermission = permissions.find((resource) => resource.resourceName === 'order');
    const subscriptionPermission = permissions.find((resource) => resource.resourceName === 'subscription');
    const allCustomerData = useSelector((state) => state.CustomerAllData.customerListForReservation)
    let list = lists
    const stationData = useSelector((state) => state.Stations.station);
    const reservationData = useSelector(
        (state) => state.Reservations.reservations
    );
    const customerData = useSelector(
        (state) => state.FetchCustomer.fetchCustomer
    );
    const fetchCustomerSummaryData = useSelector(
        (state) => state.Simulate.simulate
    );
    noCurrencyArray = noCurrencyArrayDataFormat(
        fetchCustomerSummaryData,
        noCurrencyArray
    );
    let attributeDataArray = customerData?.customerInfo?.attributeList || [];

    currencyArray = currencyDataFormat(fetchCustomerSummaryData, currencyArray);
    subscriptionsArray = subscriptionDataFormat(customerData, subscriptionsArray);
    reservationDataArray = reservationDataFormat(
        reservationData,
        customerData,
        stationData,
        reservationDataArray
    );
    const customers = useSelector((state) => state?.CustomerList?.customerList?.Customers);
    const TotalCustomers = useSelector((state) => state?.CustomerList?.customerList);
    const columns = customerColumns(selectAllCustomers, setSelectAllCustomers, customers, setSelectedCustomer, selectedCustomer, checkboxRef, isMobile);

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = selectedCustomer?.length > 0 && selectedCustomer?.length < customers?.length;
        }
        setSelectAllCustomers(selectedCustomer?.length > 0 && customers?.length > 0 && selectedCustomer?.length === customers?.length);
    }, [selectedCustomer, customers?.length, setSelectAllCustomers]);

    useEffect(() => {
        if (!customerPermission?.autoload) {
            const [lastFilter] = [...columnFilters].slice(-1);
            if (lastFilter) {
                const selectedId = lastFilter.id === "FullName" ? 'Name' : lastFilter.id;
                const value = lastFilter.value;

                setSelectedValue(selectedId);
                setSearchValue(value);

                dispatch(fetchCustomerList(setLoading, 1, TotalCustomers?.TotalCount, selectedId, value));
            } else {
                if (rolename === "telcoAdmin") {
                    dispatch(fetchCustomerList(setLoading, 1, 5));

                } else {
                    setSearchValue('');
                    dispatch(fetchCustomerList(setLoading, 1, pagination.pageSize));
                }
            }
        }
    }, [columnFilters]);

    useEffect(() => {
        if (rolename !== "telcoAdmin") {
            dispatch(fetchCustomerListForReservation(setLoading))
        }
        else {
            dispatch(getAttributeByType("customer", setLoading))
        }
    }, [])

    const searchableAttributes = useSelector((state) => state.GetAttributeByType.getSearchableAttribute)
    const integerAndTextAttributes = searchableAttributes.filter(item => {
        return item.valueType === "integer" || item.valueType === "string";
    });
    const [searchFields, setSearchFields] = useState([])
    useEffect(() => {
        if (rolename === "telcoAdmin" || rolename === "telcoOperationUser" || rolename === "telcoBusinessUser") {
            dispatch(fetchCustomerList(setLoading, 1, 5));
        }
    }, [rolename, dispatch]);

    useEffect(() => {
        if (integerAndTextAttributes.length > 0) {
            setSearchFields(integerAndTextAttributes.map(attr => ({ name: attr.name, value: '', touched: false })));
        }
    }, [searchableAttributes]);

    useEffect(() => {
        if (showWelcomeToast) {
            toast.success(`Welcome ${localStorage.getItem("userName")}`);
            dispatch(setWelcomeToast(false));
        }
        if (showSuccessMessage) {
            toast.success(`Customer Created Successfully`);
            dispatch(setSuccessMessage(false));
        }
        if (showEditSuccessMessage) {
            toast.success(`Customer Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
        if (showDocumentSuccessMessage) {
            toast.success(`Subscription Created Successfully`);
            dispatch(setDocumentSuccessMessage(false));
        }
    }, [showSuccessMessage, showDocumentSuccessMessage, showWelcomeToast, dispatch]);

    useEffect(() => {
        if (!customerPermission?.autoload) {
            setIsLoading(true);
            setIsRefetching(true);
            const fetchData = async () => {
                setIsLoading(true);
                setIsRefetching(true);

                try {
                    await dispatch(
                        fetchCustomerList(
                            setLoading,
                            pagination.pageIndex + 1,
                            pagination.pageSize,
                        )
                    );
                    setRowCount(customers.length);

                } catch (error) {
                    setIsError(true);
                } finally {
                    setIsLoading(false);
                    setIsRefetching(false);
                }
            };
            fetchData();
        }
    }, [pagination.pageIndex, pagination.pageSize]);
    if (customers) {
        exportData = exportDataForCustomer(allCustomerData)
    }

    const searchCustomerData = useSelector((state) => state.SearchedCustomer.searchCustomer)
    let searchedCustomerDataJsonObj
    if (searchCustomerData) {
        searchedCustomerDataJsonObj = searchCustomerJsonData(searchCustomerData)
    }
    const searchColumns = customerSearchColumns()
    const pageTitle = rolename === 'telcoAdmin' ? PAGE_TITLES.TELCO_CUSTOMER : PAGE_TITLES.CUSTOMER_LIST;

    return (
        <React.Fragment>
            {!isAIPath ? (
                <div id="csms" className="page-content">
                    <Container fluid>
                        <BreadCrumb title={pageTitle} pageTitle={PAGE_TITLES.CUSTOMER_LIST} />
                        <Row style={{ marginTop: '10px' }}>
                            <ToastContainer position="top-center" />
                            <Col lg={12}>
                                <div className="listingjs-table " id="customerList">
                                    <Card>
                                        <CardBody>
                                            {!customerPermission?.autoload ?
                                                <Row className="mb-3 mt-1 table-responsive">
                                                    {customers ? (
                                                        <MaterialReactTable
                                                            icons={{
                                                                DragHandleIcon: () => (
                                                                    <i className="ri-drag-move-fill" />
                                                                ),
                                                            }}
                                                            renderTopToolbarCustomActions={({ table }) => (
                                                                <CustomerTableCustomTableButtons selectedCustomer={selectedCustomer} toggleModal={toggleModal} setMessageForPopUp={setMessageForPopUp} setCustomerArray={setCustomerArray} table={table} navigate={navigate} customerPermission={customerPermission} exportData={exportData} />
                                                            )}
                                                            displayColumnDefOptions={{
                                                                'mrt-row-actions': {
                                                                    header: '',
                                                                    enableHiding: true,
                                                                    size: isMobile ? 60 : 36,
                                                                    grow: true
                                                                },
                                                            }}
                                                            defaultColumn={{
                                                                minSize: 1,
                                                                maxSize: 9001,
                                                                size: 260,
                                                                muiTableBodyCellProps: {
                                                                    sx: {
                                                                        fontFamily: "Inter,sans-serif"
                                                                    }
                                                                },
                                                                muiTableHeadCellProps: {
                                                                    sx: {
                                                                        fontFamily: "Inter,sans-serif"
                                                                    }
                                                                }
                                                            }}
                                                            layoutMode='grid'
                                                            muiTableDetailPanelProps={{
                                                                sx: {
                                                                    fontFamily: "Inter,sans-serif",
                                                                },
                                                            }}
                                                            enableGlobalFilter={false}
                                                            enableColumnOrdering={true}
                                                            enableColumnDragging={true}
                                                            enableDensityToggle={false}
                                                            positionToolbarAlertBanner='none'
                                                            columns={columns}
                                                            data={customers}
                                                            manualPagination={true}
                                                            onPaginationChange={setPagination}
                                                            {...(searchValue === '' ? { rowCount: TotalCustomers?.TotalCount } : { rowCount: TotalCustomers?.Count })}
                                                            positionGlobalFilter="right"
                                                            muiSearchTextFieldProps={{
                                                                placeholder: `Search ${customers.length} rows`,
                                                                sx: { minWidth: "300px" },
                                                                variant: "outlined",
                                                            }}
                                                            manualFiltering
                                                            onColumnFiltersChange={setColumnFilters}
                                                            enableGlobalFilterModes
                                                            state={{
                                                                isLoading,
                                                                ...(searchValue === '' ? { pagination } : {}),
                                                                showProgressBars: isRefetching,
                                                                columnFilters,
                                                                showColumnFilters: true
                                                            }}
                                                            muiSelectCheckboxProps={{
                                                                color: 'warning'
                                                            }}
                                                            muiSelectAllCheckboxProps={{
                                                                color: 'warning'
                                                            }}
                                                            enableRowActions={true}
                                                            initialState={{
                                                                columnVisibility: {
                                                                    "mrt-row-expand": false,
                                                                },
                                                                columnOrder: [
                                                                    'checkbox',
                                                                    'mrt-row-actions',
                                                                    'CustomerId',
                                                                    'FullName',
                                                                    'Email',
                                                                    'Mobile',
                                                                    'Address'
                                                                ]
                                                            }}
                                                            renderRowActionMenuItems={({ closeMenu, row }) => (
                                                                [
                                                                    customerPermission && customerPermission.permissionList.includes('view') ? <MenuItem className='row-options' key="view" onClick={() => { simulateOrPreview = "View"; navigate(`/view-customer/${row.original.CustomerId}`) }}>
                                                                        <i className='ri  ri-account-circle-line me-2' /> View
                                                                    </MenuItem> : null,
                                                                    customerPermission && customerPermission.permissionList.includes('update') ? <MenuItem className='row-options' key="edit" onClick={() => { navigate(`/update-customer/${row.original.CustomerId}`) }}>
                                                                        <i className='bx bxs-edit me-2' /> Edit
                                                                    </MenuItem> : null,
                                                                    <MenuItem className='row-options' key="delete" onClick={(e) => {
                                                                        simulateOrPreview = "Simulate"
                                                                        fetchCustomerDetails(dispatch, setLoading, setRenderSimulate, row, simulateOrPreview)
                                                                        storeRow(row, setIndex, index), setDefault(row.index, setActiveTab)
                                                                        closeMenu()
                                                                    }}>
                                                                        <i className=' bx bx-info-circle me-2' /> Simulate
                                                                    </MenuItem>,
                                                                    <MenuItem className='row-options' key="preview" onClick={(e) => {
                                                                        simulateOrPreview = "Preview"
                                                                        e.stopPropagation();
                                                                        getCustomerData(row, simulateOrPreview, setLoading, stationPermission, reservationPermission, customerPermission, setRenderSimulate, dispatch)
                                                                        row.toggleExpanded(); storeRow(row, setIndex, index), setDefault(row.index, setActiveTab), closeMenu()
                                                                    }}>
                                                                        <i className="ri-survey-line me-2" /> Preview
                                                                    </MenuItem>,
                                                                    orderPermission && orderPermission.permissionList.includes('create') ? <MenuItem className='row-options' key="subscribe" onClick={() => {
                                                                        navigateToSubscribe(row, dispatch, navigate)
                                                                    }}>
                                                                        <i className="ri-add-circle-line me-2 " ></i> Subscribe
                                                                    </MenuItem> : null,
                                                                    customerPermission && customerPermission.permissionList.includes('delete') ? <MenuItem className='row-options'
                                                                        key="close"
                                                                        onClick={() => {
                                                                            setMessageForPopUp(`Are you sure you want to Delete this Customer "${row.original.CustomerId}"?`)
                                                                            toggleModal(row,"single");
                                                                            setCustomerId(row.original.CustomerId)
                                                                            closeMenu();
                                                                        }}
                                                                    >
                                                                        <i className="ri ri-delete-bin-5-line me-2" />{" "}
                                                                        Close
                                                                    </MenuItem> : null,
                                                                ]
                                                            )}
                                                            renderDetailPanel={({ row }) => (
                                                                <React.Fragment>
                                                                    {useEffect(() => {
                                                                        row.toggleExpanded(false);
                                                                    }, [pagination])}
                                                                    {renderSimulate ? (
                                                                        <Simulate row={row} />
                                                                    ) : (
                                                                        <CustomerPreview
                                                                            customerData={customerData}
                                                                            row={row}
                                                                            activeTab={activeTab}
                                                                            setActiveTab={setActiveTab}
                                                                            tabChange={tabChange}
                                                                            open={open}
                                                                            setOpen={setOpen}
                                                                            toggle={toggle}
                                                                            noCurrencyArray={noCurrencyArray}
                                                                            currencyArray={currencyArray}
                                                                            fetchCustomerSummaryData={fetchCustomerSummaryData}
                                                                            reservationDataArray={reservationDataArray}
                                                                            duration={duration}
                                                                            subscriptionsArray={subscriptionsArray}
                                                                            convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear}
                                                                            formatDate={formatDate}
                                                                            getDayDiff={getDayDiff}
                                                                            current={current} />
                                                                    )}
                                                                </React.Fragment>
                                                            )} />
                                                    ) : (
                                                        <MaterialReactTable
                                                            renderTopToolbarCustomActions={({ table }) => (
                                                                <CustomerTableCustomTableButtons table={table} navigate={navigate} customerPermission={customerPermission} exportData={exportData} />
                                                            )}
                                                            icons={{
                                                                DragHandleIcon: () => (
                                                                    <i className="ri-drag-move-fill" />
                                                                ),
                                                            }}
                                                            positionToolbarAlertBanner='none'
                                                            muiTableHeadCellProps={{
                                                                sx: {
                                                                    fontFamily: "Inter,sans-serif",
                                                                },
                                                            }}
                                                            muiTableBodyCellProps={{
                                                                sx: {
                                                                    fontFamily: "Inter,sans-serif",
                                                                },
                                                            }}
                                                            muiTableDetailPanelProps={{
                                                                sx: {
                                                                    fontFamily: "Inter,sans-serif",
                                                                },
                                                            }}
                                                            initialState={{
                                                                columnVisibility: {
                                                                    "mrt-row-expand": false,
                                                                },
                                                                columnOrder: [
                                                                    'checkbox',
                                                                    'mrt-row-actions',
                                                                    'CustomerId',
                                                                    'FullName',
                                                                    'Email',
                                                                    'Mobile',
                                                                    'Address'
                                                                ]
                                                            }}
                                                            muiSelectCheckboxProps={{
                                                                color: 'warning'
                                                            }}
                                                            muiSelectAllCheckboxProps={{
                                                                color: 'warning'
                                                            }}
                                                            columns={columns}
                                                            data={[]}
                                                            enableRowActions={true}
                                                            enableColumnOrdering={true}
                                                            enableColumnDragging={true}
                                                            enableDensityToggle={false}
                                                            displayColumnDefOptions={{
                                                                "mrt-row-actions": {
                                                                    header: "",
                                                                    enableHiding: true,
                                                                },
                                                            }}
                                                            enablePagination={false}
                                                            state={{
                                                                showColumnFilters: true
                                                            }}
                                                        />
                                                    )}
                                                    <CommonModal messageForPopUp={messageForPopUp} toggle={() => { if (modal) { toggleModal(customerId, "single") } else { toggleModal(selectedRowsForBulkDelete, "multi") } }}
                                                        open={modalForBulkDelete ? modalForBulkDelete : modal} buttonText={"Yes, Delete It"} modalAction={() => {
                                                            if (modal) {
                                                                dispatch(deletecustomer(customerId, setLoading, 1, pagination))
                                                                toggleModal(customerId, "single");
                                                            }
                                                            else {
                                                                let jsonObj = { ids: customerArray, type: "Customer" }
                                                                dispatch(bulkDelete(jsonObj, setLoading, pagination?.pageIndex + 1, pagination?.pageSize))
                                                                setSelectAllCustomers(false)
                                                                setSelectedCustomer([])
                                                                toggleModal(selectedRowsForBulkDelete, "multi")
                                                            }
                                                        }} />
                                                </Row>
                                                : <Row className="mb-3 mt-1 table-responsive">
                                                    <TelcoHeaderForCustomer setCustId={setCustId} custId={custId} searchCustomerData={searchCustomerData} setRenderSimulate={setRenderSimulate} stationPermission={stationPermission} reservationPermission={reservationPermission} customerPermission={customerPermission} searchFields={searchFields} setSearchFields={setSearchFields} integerAndTextAttributes={integerAndTextAttributes} setErrorMessage={setErrorMessage} setErrorIcon={setErrorIcon} msisdn={msisdn} imsi={imsi} accountNumber={accountNumber} errors={errors} dispatch={dispatch} setLoading={setLoading} setGetTable={setGetTable} setMsisdn={setMsisdn} setImsi={setImsi} setAccountNumber={setAccountNumber} navigate={navigate} setErrors={setErrors} searchExpanding={searchExpanding} setSearchExpanding={setSearchExpanding} />
                                                    <TableForTelco messageForPopUp={messageForPopUp} modal={modal} customerId={customerId} errorMessage={errorMessage} errorIcon={errorIcon} getTable={getTable} searchColumns={searchColumns} searchData={searchedCustomerDataJsonObj} orderPermission={orderPermission} customerPermission={customerPermission} navigate={navigate} dispatch={dispatch} setRenderSimulate={setRenderSimulate} setLoading={setLoading} simulateOrPreview={simulateOrPreview} index={index} setIndex={setIndex} setActiveTab={setActiveTab} fetchCustomerDetails={fetchCustomerDetails} stationPermission={stationPermission} reservationPermission={reservationPermission} getCustomerData={getCustomerData} storeRow={storeRow} setDefault={setDefault} renderSimulate={renderSimulate} pagination={pagination} customerData={customerData} activeTab={activeTab} tabChange={tabChange} setOpen={setOpen} toggle={toggle} noCurrencyArray={noCurrencyArray} currencyArray={currencyArray} fetchCustomerSummaryData={fetchCustomerSummaryData} reservationDataArray={reservationDataArray} duration={duration} subscriptionsArray={subscriptionsArray} convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear} formatDate={formatDate} getDayDiff={getDayDiff} current={current} setGetTable={setGetTable} searchExpanding={searchExpanding} setSearchExpanding={setSearchExpanding} toggleModal={toggleModal} setMessageForPopUp={setMessageForPopUp} setCustomerId={setCustomerId} TotalCustomers={TotalCustomers} />
                                                </Row>}
                                        </CardBody>

                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <div className="p-4">
                    {TableShow ? (
                        <CardBody>
                            <TableForAI errorMessage={errorMessage} errorIcon={errorIcon} getTable={AISetTable} searchColumns={searchColumns} searchData={AIData} orderPermission={orderPermission} customerPermission={customerPermission} navigate={navigate} dispatch={dispatch} setRenderSimulate={setRenderSimulate} setLoading={setLoading} simulateOrPreview={simulateOrPreview} index={index} setIndex={setIndex} setActiveTab={setActiveTab} fetchCustomerDetails={fetchCustomerDetails} stationPermission={stationPermission} reservationPermission={reservationPermission} getCustomerData={getCustomerData} storeRow={storeRow} setDefault={setDefault} renderSimulate={renderSimulate} pagination={pagination} customerData={customerData} activeTab={activeTab} tabChange={tabChange} setOpen={setOpen} toggle={toggle} noCurrencyArray={noCurrencyArray} currencyArray={currencyArray} fetchCustomerSummaryData={fetchCustomerSummaryData} reservationDataArray={reservationDataArray} duration={duration} subscriptionsArray={subscriptionsArray} convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear} formatDate={formatDate} getDayDiff={getDayDiff} current={current} TableShow={TableShow} toggleModal={toggleModal} setMessageForPopUp={setMessageForPopUp} setCustomerId={setCustomerId} />
                        </CardBody>) : null}

                </div>
            )}
            {loading && <Loader />}
        </React.Fragment>
    );
}