import { registerOffer } from "../../../../slices/offer/addoffer/thunk"
import { offerJsonObj } from "./OfferJsonObj"
import { storeRow, setDefault } from "../../../Utils/SetDefaultRowData"
import { deleteOffer } from "../../../../slices/offer/deleteoffer/thunk"
import moment from "moment"
import { updateOfferJson } from "./UpdateOfferJsonObj"
import { updateOffer } from "../../../../slices/offer/updateoffer/thunk"
import { csvUpload } from "../../../../slices/offer/importdata/thunk"
import { GrantsValidityEnd, SubscriptionStartsOnOptions, TimePeriodGrantOptions, ValidityStartsOnOptions } from "./initialValues"
export const grantsAndChargesHandle = (particularRowValue, values, displayInfiniteForOptions, displayCeilingAndFloorForOptions, setFieldValue, setFieldTouched, index, resourceData, setInfinite, setCeiling, setFloor) => (val) => {
    values.resourceType = val
    displayInfiniteForOptions(val, index, resourceData, setInfinite)
    displayCeilingAndFloorForOptions(val, index, values, resourceData, setCeiling, setFloor)
    setFieldValue(`${particularRowValue}`, val);
    setFieldTouched(`${particularRowValue}`, false);
}

export const handleGrantsAndChargesResourceValueAndUsageFields = (handleChange) => (e) => {
    handleChange(e);
}

export const handleInfiniteForGrantsAndCharges = (particularRowValue, values, setFieldValue) => (val) => {
    values.infinite = val
    setFieldValue(`${particularRowValue}`, val);
}

export const handleGrantsAndChargesRecurringFrequencyAndUsageOptions = (values, particularRowValue, setFieldValue, setFieldTouched) => (val) => {
    values = val
    setFieldValue(`${particularRowValue}`, val);
    setFieldTouched(`${particularRowValue}`, false);
}

export const onSubmtiFunctionForOffer = (dispatch, selectedService, resourceData, state, globalState, serviceCode, setLoading, navigate, isEdit, serviceData, addOnOffer,isClone) => (values) => {
    if ((values.offerName !== "" && values.offerCode !== "") || (values.offerName !== "" && values.offerCode !== "" && isClone)) {
        let jsonObj = offerJsonObj(values, selectedService, resourceData, state, globalState, serviceCode, serviceData, addOnOffer,isEdit)
        if(isEdit){
            updateOfferApi(jsonObj, dispatch, setLoading, navigate, values)
        }
        else{
            submitOffer(jsonObj, dispatch, setLoading, navigate)
        }
    }

}

export const convertToJsonObj = (values, dispatch, selectedService, resourceData, state, globalState, serviceCode, setLoading, navigate, serviceData, addOnOffer) => {
    let jsonObj = offerJsonObj(values, selectedService, resourceData, state, globalState, serviceCode, serviceData, addOnOffer)
    submitOffer(jsonObj, dispatch, setLoading, navigate)
}

export const updateOfferApi = (jsonObj, dispatch, setLoading, navigate, values) => {
    dispatch(updateOffer(setLoading, values.offerCode, jsonObj, navigate))
}

export const submitOffer = (jsonObj, dispatch, setLoading, navigate) => {
    dispatch(registerOffer(jsonObj, setLoading, navigate))
}

//future use
// export const viewForOffer = (row,setIndex,index,setActiveTab,closeMenu) => {
//     storeRow(row, setIndex, index), row.toggleExpanded(), setDefault(row.index, setActiveTab), closeMenu() 
// }

export const cancelOffer = (dispatch, offerCode, setLoading) => {
    dispatch(deleteOffer(offerCode, setLoading))
}

export const navigateToAddOffer = (navigate) => {
    let path = "/create-offer"
    navigate(path)
}

export const editGrantsAndChargesFunction = (values, resp, ceiling, floor) => {
    values.push({
        "resourceType": { value: resp?.resourceName, label: resp?.resourceName },
        "resourceValue": resp?.amount,
        ...(resp?.ceiling || resp?.floor ? { "infinite": resp?.ceiling ? { value: "Ceiling", label: "Ceiling" } : { value: "Floor", label: "Floor" } } : {}),
        "ceiling": ceiling,
        "floor": floor,
        "type":(resp?.type).toString(),
        "validityStartsOn": ValidityStartsOnOptions?.find(option => option?.value === resp?.customValidity?.startTimeType),
        "validityEndsOn": GrantsValidityEnd?.find(option => option?.value === resp?.customValidity?.endTimeType),
        "grantValidityEndsOn": TimePeriodGrantOptions?.find(option => option?.value === resp?.customValidity?.relativeEndTimeUnit),
        "grantValidityEndsOnTimeperiod": resp?.customValidity?.relativeEndTimeValue

    })
}

export const editGrantsAndChargesRecurringFunction = (values, resp, ceiling, floor, recurringFrequencyOptions) => {
    values.push({
        "recurringFrequency": recurringFrequencyOptions?.find(option => parseInt(option?.value) === resp?.recurringFrequency),
        "resourceType": { value: resp?.resourceName, label: resp?.resourceName },
        "resourceValue": resp?.amount,
        ...(resp?.ceiling || resp?.floor ? { "infinite": resp?.ceiling ? { value: "Ceiling", label: "Ceiling" } : { value: "Floor", label: "Floor" } } : {}),
        "ceiling": ceiling,
        "floor": floor,
        "type":(resp?.type).toString(),
        "prorate":resp?.prorate,
        "cycleAlignment": resp?.cycleAlignment?.value,
        "validityStartsOn": SubscriptionStartsOnOptions?.find(option => option?.value === resp?.customValidity?.startTimeType),
        "validityEndsOn": GrantsValidityEnd?.find(option => option?.value === resp?.customValidity?.endTimeType),
        "grantValidityEndsOn": TimePeriodGrantOptions?.find(option => option?.value === resp?.customValidity?.relativeEndTimeUnit),
        "grantValidityEndsOnTimeperiod": resp?.customValidity?.relativeEndTimeValue
    })
}

export const getFile = (setFile, handleChange, setValidationTriggered) => (e) => {
    handleChange(e);
    setValidationTriggered(true);
    const file = e.target.files[0];
    if (file) {
        if (file.size > 10 * 1024 * 1024) {
            setValidationTriggered(false);
        } else {
            setFile(file);
        }
    }
};

export const handleDownloadClick = () => {
    const csvContent = `FnaProductCode,ProductName,Service Description,Generation,Brand,Term,Type,IsRenewable,TotalList,TotalDiscounts,NetPrice,Currency,Region,Bundled Products,ResourceName,Action
    BDDTest-TOYEREMOTTBIL0120210219,eREMOTE,eConnect Remote (No Key),21MM,Toyota,1,Paid,TRUE,8.00,0.00,8.00,USD,US,,BDDTest-CHARGED_RESOURCE,CREATE
    BDDTest-TOYREMLTSTBIL0120210219,REMLTS,Remote Lite S (Smart),21MM,Toyota,1,Paid,TRUE,8.00,0.00,8.00,USD,US,,BDDTest-CHARGED_RESOURCE,CREATE
    BDDTest-TOYNAVPKGTBIL0120210624,NAVPKG,Virtual Assistant + Navi + Dest Assist,21MM,Toyota,1,Paid,TRUE,16.00,0.00,8.00,USD,US,BDDTest-TOYEREMOTTBIL0120210219:BDDTest-TOYREMLTSTBIL0120210219,BDDTest-CHARGED_RESOURCE,CREATE`;
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'sample.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const csvFormData = (file, setLoading, navigate, dispatch) => {
    const formData = new FormData()
    formData.append("product_price_offers", file);
    dispatch(csvUpload(setLoading, navigate, formData))
}

export function isEmptyOrInvalidFieldForPricingModel(index, values) {
    const previousField = values.pricingModel[index]
    return !previousField || !previousField.startingUnit || !previousField.endingUnit || !previousField.pricePerUnit
}