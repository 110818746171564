import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, CardHeader, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Row, Col, Button } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import { handleExportData } from "../../Utils/ExportToCsv";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllResources } from "../../../slices/resources/thunk";
import { ResourcecolumnData } from "./constants/ColumnForResource";
import { deleteresource } from "../../../slices/resources/deleteresource/thunk";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "../../Utils/Loader";
import { setEditSuccessMessage, setSuccessMessage } from "../../../slices/toastMessage/action";
import CommonModal from "../../Utils/CommonModal";
import { PAGE_TITLES } from "../../Common/constants";
import { CreatableSelectField } from '../../Utils/CreatableSelectField';
import { createView } from '../../../slices/View/CreateView/thunk';
import { getView } from '../../../slices/View/thunk';
import SettingsModal from "./settingModel";
import { useColumnSettings } from "../../Utils/useColumnSettings";
import { bulkDelete } from "../../../slices/BulkDelete/thunk";

function ResourcesListings() {
  const [resources, setResources] = useState([]);
  const [messageForPopUp, setMessageForPopUp] = useState('')
  const allResourceData = [];
  const isCurrency = [];
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [resourceName, setResourceName] = useState("");
  const [modalForBulkDelete, setModalForBulkDelete] = useState(false)
  const [selectedRowsForBulkDelete, setSelectedRowsForBulkDelete] = useState()
  const [resourceArray, setResourceArray] = useState([]);
  const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
  const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
  const permissions = useSelector((state) => state.permissions.permissions);
  const resourcePermission = permissions.find((resource) => resource.resourceName === 'resource');
  const view = useSelector((state) => state.View.getView);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [selectAllResource, setSelectAllResource] = useState(false)
  const [selectedResource, setSelectedResource] = useState([])
  const checkboxRef = useRef(null);
  const isMobile = window.innerWidth <= 768;
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = (id, singleOrMulti) => {
    if (singleOrMulti === "single") {
      setResourceName(id);
      setModal(!modal);
    }
    else {
      setSelectedRowsForBulkDelete(id)
      setModalForBulkDelete(!modalForBulkDelete)
    }
    document.body.style.overflow = 'auto'
  };
  useEffect(() => {
    dispatch(getAllResources(setLoading));
    dispatch(getView(setLoading));
  }, [dispatch]);
  const resourceData = useSelector((state) => state.Resources.resources);
  useEffect(() => {
    resourceData.forEach((anotherElement) => {
      allResourceData.push(anotherElement);
    });
    setResources(allResourceData);
  }, [resourceData]);
  const navigateToAddResources = () => {
    let path = "/create-resources";
    navigate(path);
  };
  const handleDeleteResource = async (id) => {
    dispatch(deleteresource(id, setLoading, navigate));
    const updatedResources = resources.filter(
      (resource) => resource.name !== id.original.name
    );
    setResources(updatedResources);
  };
  resources.forEach((resource, index) => {
    if (resource && resource.isCurrency === true) {
      isCurrency[index] = "Monetary";
    } else {
      isCurrency[index] = "Non-Monetary";
    }
  });
  useEffect(() => {
    if (showSuccessMessage) {
      toast.success("Resource Created Successfully");
      dispatch(setSuccessMessage(false))
    }
    if (showEditSuccessMessage) {
      toast.success("Resource Updated Successfully");
      dispatch(setEditSuccessMessage(false));
    }
  }, [showSuccessMessage, showEditSuccessMessage, dispatch])

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = selectedResource.length > 0 && selectedResource.length < resources.length;
    }
    setSelectAllResource(selectedResource?.length > 0 && resources?.length > 0 && selectedResource?.length === resources?.length);
  }, [selectedResource, resources.length, setSelectAllResource]);

  let columns = ResourcecolumnData(isCurrency, selectAllResource, setSelectAllResource, resources, setSelectedResource, selectedResource, checkboxRef, isMobile, modalOpen);

  const {
    settingsModalOpen,
    viewColumns,
    toggleSettingsModal,
    SettingsSidebar
  } = useColumnSettings('resource', columns);

  useEffect(() => {
    setModalOpen(settingsModalOpen)
  }, [settingsModalOpen])


  return (
    <React.Fragment>
      <div id="csms" className="page-content">
        <Container fluid>
          <BreadCrumb title={PAGE_TITLES.RESOURCE_LIST} pageTitle={PAGE_TITLES.PRICING} />
          <ToastContainer position="top-center" />
          <Row>
            <Col lg={settingsModalOpen ? 10 : 12}>
              <div className="listingjs-table" id="customerList">
                <Card>
                  <CardBody>
                    <Row className="mb-3 mt-1 table-responsive">
                      {loading && (<Loader />)}
                      {resources ? (
                        <MaterialReactTable
                          columns={columns.filter((column) => viewColumns?.includes(column.accessorKey || column.header))}
                          data={resources}
                          enableDensityToggle={false}
                          enableColumnOrdering
                          enableColumnDragging
                          enableRowActions
                          defaultColumn={{
                            minSize: 1,
                            maxSize: 9001,
                            size: 260,
                            muiTableBodyCellProps: {
                              sx: {
                                fontFamily: "Inter,sans-serif"
                              }
                            },
                            muiTableHeadCellProps: {
                              sx: {
                                fontFamily: "Inter,sans-serif"
                              }
                            }
                          }}
                          icons={{
                            DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                          }}
                          displayColumnDefOptions={{
                            'mrt-row-actions': {
                              header: '',
                              enableHiding: true,
                              size: isMobile ? 59 : 32,
                              grow: true
                            },
                          }}
                          initialState={{
                            columnOrder: [
                              'checkbox',
                              'mrt-row-actions',
                              'Resource Name',
                              'Resource Type',
                              'Code',
                              'Ceiling',
                              'Floor'
                            ]
                          }}
                          layoutMode='grid'
                          positionToolbarAlertBanner='none'
                          muiSelectCheckboxProps={{
                            color: 'warning'
                          }}
                          muiSelectAllCheckboxProps={{
                            color: 'warning'
                          }}
                          renderTopToolbarCustomActions={({ table }) => (
                            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
                              <div className="d-flex flex-column flex-md-row">
                                <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(resources) }}>
                                  <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                                </Button>
                                {resourcePermission && resourcePermission.permissionList.includes('create') &&
                                  <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={navigateToAddResources}>
                                    <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Resource
                                  </Button>
                                }
                                {selectedResource?.length ? <Button color='main-color' className="btn me-2" onClick={() => {
                                  const names = selectedResource.map(row => row.name);
                                  let uniqueResources = Array.from(new Set(names))
                                  setResourceArray(uniqueResources);
                                  setMessageForPopUp(`Are you sure you want to delete the selected items?`)
                                  toggleModal(selectedResource, "multi")
                                }}>Delete</Button> : null}
                              </div>
                              <div className="d-flex align-items-center">
                                <div color="secondary" className="btn-icon me-2 cursor-img" onClick={toggleSettingsModal}>
                                  <i className="ri-settings-2-line fs-4"></i>
                                </div>
                              </div>
                            </div>
                          )}
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem key={`${row.index}.2`} onClick={() => { navigate(`/edit-resource/${row.original.name}`); }}><i className="bx bxs-edit me-2" /> Edit </MenuItem>,
                            <MenuItem className='row-options'
                              key="view"
                              onClick={() => {
                                setMessageForPopUp(`Are you sure you want to Delete this resource "${row.original.name}"?`)
                                toggleModal(row, "single");
                                closeMenu();
                              }}
                            >
                              <i className="ri ri-delete-bin-5-line me-2" />{" "}
                              Delete
                            </MenuItem>,
                          ]}
                        />
                      ) : null}
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
            {SettingsSidebar()}
          </Row>
        </Container>
      </div>
      <div>
        <CommonModal messageForPopUp={messageForPopUp} toggle={() => { if (modal) { toggleModal(resourceName, "single") } else { toggleModal(selectedRowsForBulkDelete, "multi") } }} open={modalForBulkDelete ? modalForBulkDelete : modal} buttonText={"Yes, Delete It"} modalAction={() => {
          if (modal) {
            handleDeleteResource(resourceName)
            toggleModal(resourceName, "single");
          }
          else {
            let jsonObj = { ids: resourceArray, type: "Resource" }
            dispatch(bulkDelete(jsonObj, setLoading))
            setSelectAllResource(false)
            setSelectedResource([])
            toggleModal(selectedRowsForBulkDelete, "multi")
          }
        }} />
      </div>
    </React.Fragment>
  );
}

export default ResourcesListings;