import { fetchCustomerSummary } from '../../../slices/customer/simulate/thunk';

export const reservationDataFormat = (reservationData, customerData, stationData, reservationDataArray) => {
    if (reservationData?.length > 0) {
        reservationData.forEach((value) => {
            if (customerData.customerInfo?.customerId === value.CustomerId) {
                stationData.forEach((values) => {
                    if (value.StationId === values.stationDetails?.stationID) {
                        reservationDataArray.push({
                            "name": values.stationDetails.name,
                            "address": values.stationDetails.address,
                            "startDate": value.StartTime,
                            "endDate": value.EndTime
                        })
                    }
                })
            }
        })
    }
    return reservationDataArray
}

export const subscriptionDataFormat = (customerData, subscriptionsArray) => {
    if (customerData && typeof customerData === 'object') {
        if ('subscriptionList' in customerData) {
            subscriptionsArray = customerData.subscriptionList;
        }
    }
    return subscriptionsArray;
}


export const currencyDataFormat = (fetchCustomerSummaryData, currencyArray) => {
    if ("resources" in fetchCustomerSummaryData) {
        fetchCustomerSummaryData?.resources?.forEach((value) => {
            if (!("validFrom" in value)) {
                currencyArray.push(value)
            }
        })
    } else {
        currencyArray = []
    }
    return currencyArray
}

export const noCurrencyArrayDataFormat = (fetchCustomerSummaryData, noCurrencyArray) => {
    if ("resources" in fetchCustomerSummaryData) {
        fetchCustomerSummaryData?.resources?.forEach((value) => {
            if ("validFrom" in value) {
                noCurrencyArray.push(value)
            }
        })
    } else {
        noCurrencyArray = []
    }
    return noCurrencyArray
}

export const toggleAccordion = (open, setOpen) => {
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return toggle
}

export function isEmptyOrInvalidField(index, values) {
    const previousField = values?.attributeList[index];
    return !previousField || !previousField.name || !previousField.value;
}

export const commonIDFunction = (val) => {
    const commonID = val?.map(
        (commonID) => commonID
    ) ?? [];
    return commonID
}

export const optionsForCustomerDetails = (commonID) => {
    const optionsForSelect = commonID.map((common) => ({
        value: common,
        label: common,
    }));
    return optionsForSelect
}

export const stationDataFunction = (stationsData, allStationData) => {
    stationsData.forEach((anotherElement) => {
        allStationData.push(anotherElement);
    })
    return allStationData
}

export const reservationDataFunction = (reservationData, customerId, reserve) => {
    if (reservationData?.length > 0) {
        reservationData.forEach((value, index) => {
            if (customerId === reservationData[index]?.CustomerId) {
                const reservationObject = reservationData[index]
                reserve.push(reservationObject)
            }
        })
    }
    return reserve
}

export const fetchCustomerDetails = async (dispatch, setLoading, setRenderSimulate, id, simulateOrPreview) => {
    dispatch(fetchCustomerSummary(id, setLoading, simulateOrPreview, setRenderSimulate))
};

export const handleStartDateChange = (selectedDate, index, setStartDate, startDate) => {
    const startDateList = [...startDate]
    startDateList[index] = selectedDate[0]
    setStartDate(startDateList)
}

export function isEmptyOrInvalidFieldForOffer(index, values) {
    const previousField = values.subscriptions[index];
    return !previousField || !previousField.offerCode || !previousField.validFrom || !previousField.validTo;
}