import { FormGroup, Input } from "reactstrap";
import InputField from "./InputField";
import SelectField from "./SelectField";
import { LabelText } from "./Input";
import CommonDateTimePicker from "./CommonDateTimePicker";
import moment from "moment";
import { CreatableSelectField } from "./CreatableSelectField";

export const RenderField = (
  { field,
    handleChange,
    handleBlur, logoFile, profile, imageOn, handleFileChange, fileInputRef,
    values, mandatoryChecked, radioValue, setRadioValue,
    touched, setMandatoryIsChecked, setSelectedState,
    errors, index, setSelectedCountry,
    setFieldTouched, setStartDate, startDate, isAttribute,
    setFieldValue, options, setIsChecked, isChecked, setMandatoryIsCheckedSelfcare, mandatoryCheckedSelfcare, setFieldError, isCounter, setIsCounter
  }
) => {
  const option = Array.isArray(options) ? options.find(opt => opt?.name === field?.name) : null;
  switch (field.type) {
    case "creatable-select":
      return (
        <CreatableSelectField values={values[field.name]} placeholder={field?.placeholder} handleChange={(value) => {
          setFieldValue(`${field.name}`, value, false);
          setFieldTouched(`${field.name}`, false);
        }}
        className={((errors[field.name] && touched[field.name]) || false
          ? field.className
          : "") }
          onKeyDown={(event) => {
            const key = event.key;
            const regex = /^[a-zA-Z0-9]$/;
            if (regex.test(key) || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(key)) {
              return;
            }
            event.preventDefault();
          }}
          name={field?.name} options={option ? option.options : []} isMulti={field?.isMulti}
          fieldLabel={field?.label}
          fieldName={field?.name}
          valuess={values}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
          errors={errors[field.name]}
          touched={touched[field.name]}
        />
      )
      case "Selfcare-creatable-select":
        return (
          <CreatableSelectField
            values={values[field.name]}
            placeholder={field?.placeholder}
            handleChange={(value) => {
              setFieldValue(`${field.name}`, value, false);
              setFieldTouched(`${field.name}`, false);
            }}
            onKeyDown={(event) => {
              const key = event.key;
              const regex = /^[a-zA-Z0-9]$/;
              if (regex.test(key) || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(key)) {
                return;
              }
              event.preventDefault();
            }}
            name={field?.name}
            options={option ? option.options : []}
            isMulti={field?.isMulti}
            fieldLabel={field?.label}
            fieldName={field?.name}
            valuess={values}
            setFieldError={setFieldError}
            isAttribute={isAttribute}
            setFieldValue={setFieldValue}
            errors={errors[field.name]}
            touched={touched[field.name]}
          />
        );      
    case "select":
      return (
        <SelectField
          name={!field?.insideTable ? field.name : `${field.arrayName}[${index}][${field.name}]`}
          placeholder={field.placeholder}
          className={!field?.insideTable ?
            ((errors[field.name] && touched[field.name]) || false
              ? field.className
              : "") : (
                touched[field.arrayName] &&
                touched[field.arrayName][index] &&
                !values[field.arrayName][index][field.name]
              ) ||
                false
              ? field.className
              : ''
          }
          closeMenuOnSelect={field.closeMenuOnSelect}
          value={!field?.insideTable
            ? values[field.name]
            : (values[field.arrayName] && values[field.arrayName][index]
              ? values[field.arrayName][index][field.name]
              : '')}
          handleChange={(value) => {
            if (!field?.insideTable) {
              setFieldValue(`${field.name}`, value, false);
              setFieldTouched(`${field.name}`, false);
              if (field?.isLocation && field.name === "country") {
                setSelectedCountry(value)
              }
              if (field?.isLocation && field.name === "state") {
                setSelectedState(value)
              }
            }
            else {
              const newArray = [...values[field.arrayName]];
              newArray[index] = {
                ...newArray[index],
                [field.name]: value,
              };
              setFieldValue(field.arrayName, newArray);
              setFieldTouched(field.arrayName, false);

            }
          }}
          isDisabled={field?.disable}
          isMulti={field?.isMulti}
          options={option ? option.options : []}
        />
      );
    case "date":
      return (
        <CommonDateTimePicker
          value={values[field.name]}
          format={field.format}
          name={field.name}
          views={field.views}
          className={`${field?.secondClassName} ${errors[field.name] && touched[field.name]
            ? field?.className
            : ""
            }`}
          handleChange={(date) => {
            setFieldTouched(field.name, false);
            setFieldValue(field.name, date)
            if (field.indicator === "start") {
              setStartDate(date)
            }
          }}
          minDate={field.minDate ? (field.indicator === "start" ? moment() : startDate) : undefined}
          minDateTime={!field.minDate ? (field.indicator === "start" ? moment() : startDate) : undefined}
        />
      )
    case "radio":
      return (
        <div className="form-group me-4">
          <LabelText classLabelName={"d-block text-black"} displayText={field.label} check />
          <InputField name={field.name} value={radioValue} handleChange={setRadioValue} className={"form-check-input"} type={field.type} defaultChecked={radioValue} />
        </div>
      )
    case "switch":
      return (
        <FormGroup switch>
          <Input
            type={field.type}
            checked={field.label === "Mandatory" ? mandatoryChecked : field.label === "Selfcare" ? mandatoryCheckedSelfcare : field.name === "Counter" ? isCounter : isChecked}
            onClick={() => {
              if (field.label === "Mandatory") {
                setMandatoryIsChecked(!mandatoryChecked);
              } else if (field.label === "Selfcare") {
                setMandatoryIsCheckedSelfcare(!mandatoryCheckedSelfcare);
              } else if (field.name === "Counter") {
                setIsCounter(!isCounter);
              } else {
                setIsChecked(!isChecked)
              }
            }}
            disabled={field?.disable}
          />
          <LabelText htmlFor={field.name} displayText={field.switchLabel} check />
        </FormGroup>
      )
    default:
      return (
        <InputField
          name={!field?.insideTable ? field.name : `${field.arrayName}[${index}][${field.name}]`}
          placeholder={field.placeholder}
          type={field.type}
          className={!field?.insideTable ?
            field.className : (
              touched[field.arrayName] &&
              touched[field.arrayName][index] &&
              !values[field.arrayName][index][field.name]
            ) ||
              false
              ? field.className
              : ''
          }
          id={field.id}
          disabled={field?.disable}
          pattern={field.pattern}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={!field?.insideTable
            ? values[field.name]
            : (values[field.arrayName] && values[field.arrayName][index]
              ? values[field.arrayName][index][field.name]
              : '')}
          invalid={!field?.insideTable
            ? (touched[field.name] && errors[field.name] ? true : false) :
            (errors[field.arrayName] &&
              errors[field.arrayName][index] &&
              touched[field.arrayName] &&
              touched[field.arrayName][index] &&
              touched[field.arrayName][index][field.name]
              ? true
              : false)}
        />
      );
  }
};