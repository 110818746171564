import { Box } from '@mui/material';
import { getCellStyle } from '../../../Utils/DecideColor';
import { RatePlanOrAttributesColumn } from '../../../Utils/RatePlanColumn';
import { Input } from 'reactstrap';

export const useColumns = (
  allStartDate,
  allEndDate,
  allModifyDate,
  isBundle,
  isProvisioning,
  isUsageAttribute,selectAllService, setSelectAllService, serviceData, setSelectedService, selectedService, checkboxRef, isMobile,modalOpen
) => {
  return [
    {
      accessorKey: 'checkbox',
      header: 'checkbox',
      Header: ({ row }) => (
        <Input type="checkbox" ref={checkboxRef} className=' border-black checkbox-size rounded-1' checked={selectAllService} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
          setSelectAllService(!selectAllService)
          if (!selectAllService) {
            setSelectedService(serviceData)
          }
          else {
            setSelectedService([])
          }
        }} />
      ),
      enableColumnActions: false,
      enableColumnDragging: false,
      Cell: ({ row }) => (
        <Input type="checkbox" className=' border-black checkbox-size  rounded-1' checked={selectedService.some(p => p.serviceCode === row.original.serviceCode)} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
          handleCheckboxChange(row, selectedService, setSelectedService)
        }
        } />
      ),
      size: isMobile ? 50 : modalOpen ? 40 :30
    },
    {
      accessorFn: (row) => `${row.name}`,
      header: 'Name',
      size: 150,
    },
    {
      accessorFn: (row) => `${row.serviceCode}`,
      header: 'Service Code',
      size: 150,
    },
    {
      accessorFn: (service, i) => `${isProvisioning[i]} ${isUsageAttribute[i]}`,
      header: 'Attributes',
      size: 150,
      Cell: ({ cell }) => {
        const attributes = cell.getValue();
        const [provisional, usage] = attributes?.split(' ');
        return (
          <RatePlanOrAttributesColumn provisioning={provisional} serviceUsage={usage} />
        );
      },
    },
    {
      accessorFn: (service, i) => `${isBundle[i]}`,
      header: 'Bundled',
      size: 150,
    },
    {
      accessorFn: (service, i) => `${allStartDate[i]}-${allEndDate[i]}`.trim(),
      header: 'Validity',
      size: 150,
    },
    {
      accessorFn: (service, i) => `${allModifyDate[i]}`,
      header: 'Last Modified',
      size: 150,
    },
  ];
};


export const handleCheckboxChange = (row, selectedService, setSelectedService) => {
  let isChecked = selectedService.some(p => p.serviceCode === row.original.serviceCode);
  let updatedSelectedService = isChecked
    ? selectedService.filter(p => p.serviceCode !== row.original.serviceCode)
    : [...selectedService, row.original];
  setSelectedService(updatedSelectedService);
};
